<template>
  <div class="company px-2 py-2">
    <div class="row mb-3 justify--space-between">
      <div class="flex align-self--center">
        <va-breadcrumbs>
          <va-breadcrumbs-item label="项目基信息" />
          <va-breadcrumbs-item label="添加项目" />
        </va-breadcrumbs>
      </div>
      <div class="justify-self--end">
        <va-button :rounded="false" size="small" class="px-2" @click="returnToList" outline flat>返回</va-button>
      </div>
    </div>
    
    <va-form ref="recordForm" class="form" @validation="false">
      <div class="row" ref="dragUploadContainer" style="position:relative;">
        <div class="flex md6 xs12">
          <va-input class="" label="公司名称" v-model="record.companyName" :requiredMark="true" :disabled="formReadonly" outline :rules="[value => (value && value.length > 0) || '必填']" />
        </div>
        <div class="flex md6 xs12">
          <va-select label="项目类别" :disabled="formReadonly" :requiredMark="true" outline v-model="record.projectType" :options="selectOptions.projectTypes" :text-by="option => option.key" :value-by="option => option.value" clearable searchable
            :rules="[value => value || '必填']" />
        </div>
        <div class="flex md6 xs12">
          <va-select label="企业类型" outline v-model="record.companyType" :disabled="formReadonly"  :options="selectOptions.companyType" :text-by="option => option.key" :value-by="option => option.value" clearable />
        </div>
        <div class="flex md6 xs12">
          <va-input label="投资方" v-model="record.investors" :disabled="formReadonly"  outline />
        </div>
        <div class="flex md6 xs12">
          <va-select label="行业分类" outline v-model="record.industryTypeId" :disabled="formReadonly"  :options="selectOptions.industryTypes" :text-by="option => option.key" :value-by="option => option.value" clearable searchable />
        </div>
        <div class="flex md6 xs12">
          <va-input class="" label="注册资本" outline v-model="record.registeredCapital" :disabled="formReadonly" />
        </div>
        <div class="flex md6 xs12">
          <va-input class="" label="预计营收" outline v-model="record.approximateBusinessIncomming" :disabled="formReadonly"  />
        </div>
        <div class="flex md6 xs12">
          <va-input class="" label="预计税收" outline v-model="record.approximateTaxInComming" :disabled="formReadonly"  />
        </div>
        <div class="flex md6 xs12">
          <va-input label="项目来源" v-model="record.companySource" :disabled="formReadonly" outline  />
        </div>
        <div class="flex md6 xs12">
          <va-select label="项目状态" outline v-model="record.projectStatus" :options="selectOptions.projectStatus" :text-by="option => option.key" :value-by="option => option.value" :disabled="formReadonly" clearable />
        </div>
        <div class="flex md6 xs12">
          <va-date-input label="企业成立时间" :disabled="formReadonly" v-model="record.settleTime"  clearable :format="formatDate" outline />
        </div>
        <div class="flex md12 xs12">
          <picture-uploader class="px-2" :dragContainer="dragUploadContainer" ref="imgUploader" v-model:files="imgList" v-model:deletedFiles="deletedImgs" v-model:dirty="imgUploaderChanged"></picture-uploader>
        </div>
        <div class="flex md12 xs12">
          <va-input class="" label="项目概况" outline v-model="record.memo" type="textarea" :min-rows="10" :max-rows="20"  :disabled="formReadonly" />
        </div>
      </div>
      <div class="mb-3">
        <va-button :rounded="false" class="px-2" @click="submit" v-if="!formReadonly">提交</va-button>
        <va-button :rounded="false" class="ml-3 px-2" @click="returnToList" outline flat>返回</va-button>
      </div>
    </va-form>
  </div>
</template>

<script lang="ts">
import { defineComponent, reactive, onMounted, computed, ref } from 'vue'
import moment from 'moment';
import { useRouter, useRoute } from 'vue-router'
import axiosService from '@/services/AxiosService'
import selectOptionService from '@/services/SelectOptionService'
import { Company, Document, FileItem } from '@/model'
import PictureUploader from '@/components/PictureUploader.vue';
import useSelectOptions from '@/common/CommonOptions';
import useUtility from '@/common/Utility'

export default defineComponent({
  components: {
    PictureUploader
  },
  setup() {
    const recordForm = ref<any>(null)
    const dragUploadContainer = ref<HTMLDivElement>()
    const route = useRoute()
    const router = useRouter()
    const record = reactive({} as Company)
    const formReadonly = computed(() => !!route.params.companyId && route.query.readonly == '1' )
    const originalCompanyName = ref('')
    const imgUploader = ref<null>(null)
    const imgList = ref<Array<FileItem>>([])
    const deletedImgs = ref<Array<Document>>([])
    const imgUploaderChanged = ref<boolean>(false)
    const { toast } = useUtility()


    function returnToList()
    {
      router.back()
      //router.push('/corporate')
    }

    function saveDocuments(companyId: string): Promise<void> {
      const isUpdate = !!record.id
      if(isUpdate && 
        imgUploaderChanged.value && 
        (imgList.value.filter(img => !!img.id).length > 0 || deletedImgs.value.length > 0))
      {
        const params = new URLSearchParams();
        deletedImgs.value.forEach(img => {
          if(img.id) {
            params.append("documentIds", img.id)
          }
        });

        axiosService.delete("api/documents", {params: params}).then(res => {
          console.log('Removed following documents from database!')
          console.log(params)
        })
      }

      const newFiles = imgList.value.filter(img => !img.id);
      if(newFiles.length > 0) {
        newFiles.forEach(doc => {
          doc.regardingObjectType = 1 //company
          doc.regardingObjecId = companyId
        })
        return axiosService.post('api/documents', newFiles)
      } else {
        return Promise.resolve()
      }
    }

     function formatDate(date: string) {
      console.log(date)
      if(date){
        return moment(date).format('yyyy/MM/DD')
      } else {
        return ''
      }
    }

    function submit() {
      if (recordForm.value.validate()) {
        Promise.resolve().then(() => {
          if(!record.id)
          {
            return axiosService.get(`odata/companys?$expand=CreatedByUser($select=id,fullName)&$filter=companyName eq '${record.companyName}'&$select=companyName`)
              .then(res => {
                if (res.data.value.length > 0) {
                  const fullNames = res.data.value.map((c: any) => c.createdByUser?.fullName || '').join(',')
                  toast(`提示：${fullNames}曾提交过该公司的基本信息, 请留意！`, 'warning', true, 5000)
                }
              }).then(() => {
                return axiosService.post('api/companys', record).then(res =>
                {
                  return res.data.id
                })
              })
          }
          else
          {
            return axiosService.get(`odata/companys/?$expand=CreatedByUser($select=id,fullName)&$filter=companyName eq '${record.companyName}' and companyName ne '${originalCompanyName.value}'`)
              .then(res => {
                if (res.data.value.length > 0) {
                  const fullNames = res.data.value.map((c: any) => c.createdByUser?.fullName || '').join(',')
                  toast(`提示：${fullNames}曾提交过该公司的基本信息, 请留意！ `, 'warning', true, 5000)
                }
              }).then(() => {
                return axiosService.patch(`api/companys/${record.id}`, record).then(res => {
                  return record.id
                })
              })
          }
        }).then((companyId: string) => {
            return saveDocuments(companyId)
        }).then(() => {
          const isNew = !!record.id
          if(isNew) {
            toast( '添加项目基本信息成功！',)
            router.push({ path: '/corporate' })
          } else {
            toast( '修改项目基本信息成功！',)
            router.push({ path: '/corporate' })
          }
        })
      }
    }

    onMounted(() => {
      if (route.params.companyId) {
        axiosService.get(`odata/companys/${route.params.companyId}`).then(res => {
          if (res.data) {
            record.id = res.data.id
            record.companyName = res.data.companyName
            originalCompanyName.value = res.data.companyName
            record.industryTypeId = selectOptionService.optionCollection.get('industry_type')?.find(o => o.value == res.data.industryTypeId)?.value 
            record.registeredCapital = res.data.registeredCapital
            record.approximateTaxInComming = res.data.approximateTaxInComming
            record.approximateBusinessIncomming = res.data.approximateBusinessIncomming
            record.projectStatus = selectOptionService.optionCollection.get('ProjectStatus')?.find(o => o.value == res.data.projectStatus)?.value 
            record.memo = res.data.memo
            record.companySource = res.data.companySource
            record.companyType = selectOptionService.optionCollection.get('CompanyType')?.find(o => o.value == res.data.companyType)?.value
            record.projectType = selectOptionService.optionCollection.get('ProjectType')?.find(o => o.value == res.data.projectType)?.value 
            record.investors = res.data.investors
            record.settleTime = res.data.settleTime && moment(res.data.settleTime).toDate() as any
          }
        })

        // get documents
        axiosService.get(`api/companys/${route.params.companyId}/documents`).then(res => {
          if (res.data) {
            res.data.forEach((d: Document) => {
              imgList.value.push({
                  id: d.id,
                  regardingObjectType: d.regardingObjectType,
                  regardingObjecId: d.regardingObjecId,
                  fileSize: d.fileSize,
                  fileName: d.fileName,
                  mime: d.mime,
                  fileContentBase64: d.fileContentBase64,
                  fileContent: d.fileContent,
                  key: Math.random().toString(32).substring(2)
              } as FileItem)
            })
          }
        })
      }
    })

    return {
      formatDate,
      record,
      ...useSelectOptions(),
      recordForm,
      imgUploader,
      imgList,
      deletedImgs,
      imgUploaderChanged,
      dragUploadContainer,
      formReadonly,
      submit,
      returnToList
    }
  }
})
</script>

<style lang="scss" scoped>
.company {
  min-height: 100%;
  background-color: #fff;
}
</style>
